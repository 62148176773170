<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <CTableWrapper>
          <template #header>
            <div class="d-flex justify-content-between">
              Alertas
              <div class="content-box">
                <CButton
                  class="btn btn-add"
                  size="sm"
                  style="margin-right:1em"
                  @click="addAlert()"
                >
                  Añadir alerta
                </CButton>
                <CButton
                  color="info"
                  class="btn btn-info"
                  size="sm"
                  @click="seeAlertTypes()"
                >
                  Tipos de alerta
                </CButton>
                </div>
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/AlertsTable";
import {checkUser} from '../../utils/utils';
import "core-js/stable";
import "regenerator-runtime/runtime";
export default {
  name: "alertTable",
  components: { CTableWrapper },
  created() {
    if (!checkUser()) return;
  },
  methods: {
    addAlert(){
      this.$router.push("/alert/create/");
    },
    seeAlertTypes(){
      this.$router.push("/alert/types/");
    },
  },
};
</script>