var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.caption)+" ")]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","fields":_vm.fields,"items":_vm.alerts,"items-per-page":5,"sorter":{ resetable: true },"items-per-page-select":{ label: 'Alertas por página:',
                                values: [
                                  5,
                                  10,
                                  25,
                                  50,
                                  100,
                                  250,
                                  500
                                ]
      },"table-filter":{label: 'Filtro:', placeholder: 'Comience a escribir...'},"no-items-view":{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'},"pagination":""},scopedSlots:_vm._u([{key:"id",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.alert_id)+" ")])]}},{key:"alert_title",fn:function(ref){
      var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.alert_title)+" ")])]}},{key:"acciones",fn:function(ref){
      var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"danger","shape":"pill","size":"sm"},on:{"click":function($event){return _vm.deleteButton(item)}}},[_vm._v(" Eliminar ")]),_c('CModal',{attrs:{"id":"modalElim","title":"Eliminar alerta","color":"principal","show":_vm.deleteModal},on:{"update:show":function($event){_vm.deleteModal=$event}}},[_c('p',{staticClass:"text-muted"},[_vm._v(" ¿Está seguro de que desea eliminar la alerta: \""+_vm._s(_vm.alert.alert_title)+"\" ? Esta acción no se puede deshacer. ")]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('CButton',{staticStyle:{"margin-right":"1em"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteAlert(_vm.alert.alert_id)}}},[_vm._v(" Aceptar ")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.deleteModal = false}}},[_vm._v(" Volver ")])],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }